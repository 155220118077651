import * as yup from 'yup';
import { initialEventFilter } from 'src/store/eventFilter/types';
import { selectEventFilter } from 'src/store/eventFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const eventFilterSchema = yup.object().shape({
  createdBySponsorId: yup.string().nullable(),
  state: yup.string().nullable(),
  title: yup.string().nullable()
});

export const useFilterForm = () => {
  const storeEventFilter = useTypedSelector(selectEventFilter);
  const formMethods = useForm({
    defaultValues: initialEventFilter,
    mode: 'all',
    resolver: yupResolver(eventFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeEventFilter);
  }, [reset, storeEventFilter]);

  return formMethods;
};
