import { EventFilter, initialEventFilter, initialEventFilterState } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const eventFilterSlice = createSlice({
  initialState: initialEventFilterState,
  name: 'eventFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.eventFilter = initialEventFilter;
    },
    removeFilterItem: (draft, action: PayloadAction<string>) => {
      const key = action.payload as keyof EventFilter;
      draft.eventFilter[key] = initialEventFilter[key] as any;
    },
    updateFilter: (draft, action: PayloadAction<EventFilter>) => {
      draft.eventFilter = { ...initialEventFilter, ...action.payload };
    }
  }
});

export default eventFilterSlice.reducer;
export const { clearFilter, removeFilterItem, updateFilter } = eventFilterSlice.actions;
