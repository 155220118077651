import { RootState } from 'RootTypes';

export const selectMessagingRepo = (state: RootState) => state.messagingRepository;

export const selectOperatorById = (state: RootState, operatorId: string) =>
  state.messagingRepository.operatorMap.get(operatorId);

export const selectClientActivationPredictionDictionary = (state: RootState) =>
  state.messagingRepository.clientActivationPredictionDictionary;

export const selectClientActivationPrediction = (state: RootState, userId: string) =>
  state.messagingRepository.clientActivationPredictionDictionary[userId];
