import { RemoteConfigAssignorType } from 'src/views/remoteConfig/assignor/model/remoteConfigAssignor';

export interface RemoteConfigAssignorFilter {
  assignorType: RemoteConfigAssignorType;
  name: string;
  remoteConfigGroupAlias: string;
  showActive: boolean;
  showMaster: boolean;
}

export const initialRemoteConfigAssignorFilter: RemoteConfigAssignorFilter = {
  assignorType: null,
  name: '',
  remoteConfigGroupAlias: '',
  showActive: false,
  showMaster: false
};

export interface RemoteConfigAssignorFilterState {
  remoteConfigAssignorFilter: RemoteConfigAssignorFilter;
}

export const initialRemoteConfigAssignorFilterState: RemoteConfigAssignorFilterState = {
  remoteConfigAssignorFilter: initialRemoteConfigAssignorFilter
};
