import * as yup from 'yup';
import { initialRemoteConfigAssignorFilter } from 'src/store/remoteConfigAssignorFilter/type';
import { selectRemoteConfigAssignorFilter } from 'src/store/remoteConfigAssignorFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const remoteConfigAssignorFilterSchema = yup.object().shape({
  assignorType: yup.string().nullable(),
  name: yup.string().nullable()
});

export const useFilterForm = () => {
  const storeRemoteConfigAssignorFilter = useTypedSelector(selectRemoteConfigAssignorFilter);
  const formMethods = useForm({
    defaultValues: initialRemoteConfigAssignorFilter,
    mode: 'all',
    resolver: yupResolver(remoteConfigAssignorFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeRemoteConfigAssignorFilter);
  }, [reset, storeRemoteConfigAssignorFilter]);

  return formMethods;
};
