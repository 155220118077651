import {
  ActionTaken,
  IssueGroupFilter,
  IssueType,
  Message,
  UserIssueGroup,
  initialMessagesState
} from '../types';
import { AsyncThunkConfig, GetThunkAPI } from 'node_modules/@reduxjs/toolkit/dist/createAsyncThunk';
import { AuthUserType } from 'src/auth/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';
import {
  getMessages,
  issueUpdated,
  loadedMessages,
  loadedSessions,
  updateMessage
} from '../repository/slice';
import axios, { endpoints } from 'src/utils/axios';

const satisfiesFilter = (
  issue: UserIssueGroup,
  filter: IssueGroupFilter,
  operator: AuthUserType
): boolean => {
  if (filter.chatSessionId && issue.chatSessionId !== filter.chatSessionId) {
    return false;
  }
  if (!filter.includeNotOwned && issue.operatorId !== operator.userId) {
    return false;
  }
  if (!filter.includeUnassigned && issue.operatorId == null) {
    return false;
  }
  if (!issue.relatedIssues.some((a) => filter.IncludeEntityTypes.includes(a.entityType))) {
    return false;
  }
  return true;
};

export const updateReceivedFromServer = createAsyncThunk(
  'messagesRepo/updateReceived',
  async (issue: UserIssueGroup, api: GetThunkAPI<AsyncThunkConfig>) => {
    let globalState = api.getState() as RootState;
    const repoState = globalState.messagingRepository;
    const issueListState = globalState.messageSessions;
    const { user } = globalState.messagingRepository;

    if (!satisfiesFilter(issue, issueListState.activeFilter, user)) return;
    let limit = 100;
    if (repoState.sessionMessages.has(issue.chatSessionId)) {
      limit = 10;
    }

    api
      .dispatch(getMessages({ limit, sessionId: issue.chatSessionId }))
      .then((messageResult) => {
        const response = messageResult as PayloadAction<{ sessionId: string; sorted: Message[] }>;
        api.dispatch(
          loadedMessages({
            sessionId: response.payload.sessionId,
            sorted: response.payload.sorted
          })
        );
      })
      .then(() => {
        api.dispatch(issueUpdated(issue));
        if (issueListState.activeIssueGroup?.chatSessionId === issue.chatSessionId) {
          globalState = api.getState() as RootState;
          const messagesState = globalState.messagingRepository;
          api.dispatch(setMessages(messagesState.sessionMessages));
        }
      });
  }
);

export const createIssue = createAsyncThunk(
  'issueRepo/createIssue',
  async (userId: string, api: GetThunkAPI<AsyncThunkConfig>): Promise<void> => {
    await axios.post<UserIssueGroup>(endpoints.issues.createIssue(userId)).then((response) => {
      api.dispatch(issueUpdated(response.data));
      const globalState = api.getState() as RootState;
      if (
        globalState.messageSessions.activeIssueGroup?.chatSessionId === response.data.chatSessionId
      ) {
        api.dispatch(setActiveIssue(response.data));
      }
    });
  }
);

export const tendToIssue = createAsyncThunk(
  'issueRepo/tendToIssue',
  async (issueId: string, api: GetThunkAPI<AsyncThunkConfig>): Promise<void> => {
    await axios.post<UserIssueGroup>(endpoints.issues.tendToIssue(issueId)).then((response) => {
      api.dispatch(issueUpdated(response.data));
      // const globalState = api.getState() as RootState;
      // if (
      //   globalState.messageSessions.activeIssueGroup?.chatSessionId === response.data.chatSessionId
      // ) {
      //   api.dispatch(setActiveIssue(response.data));
      // }
    });
  }
);

export const disownIssue = createAsyncThunk(
  'issueRepo/disownIssue',
  async (issueId: string, api: GetThunkAPI<AsyncThunkConfig>): Promise<void> => {
    await axios.post<UserIssueGroup>(endpoints.issues.disownIssue(issueId)).then((response) => {
      api.dispatch(issueUpdated(response.data));
    });
  }
);

export const assignIssue = createAsyncThunk(
  'issueRepo/assignIssue',
  async (
    { assigneeEmail, issueId }: { assigneeEmail: string; issueId: string },
    api: GetThunkAPI<AsyncThunkConfig>
  ): Promise<void> => {
    await axios
      .post<UserIssueGroup>(
        endpoints.issues.assingIssue(issueId, encodeURIComponent(assigneeEmail))
      )
      .then((response) => {
        api.dispatch(issueUpdated(response.data));
      });
  }
);

export const filterIssues = createAsyncThunk(
  'issueRepo/filterIssues',
  async (filter: IssueGroupFilter, api: GetThunkAPI<AsyncThunkConfig>): Promise<void> => {
    api.dispatch(setFilter(filter));
    await axios.post<UserIssueGroup[]>(endpoints.issues.filterIssues, filter).then((response) => {
      api.dispatch(loadedSessions(response.data));
      const globalState = api.getState() as RootState;
      if (
        response.data.some(
          (a) => a.chatSessionId === globalState.messageSessions.activeIssueGroup?.chatSessionId
        )
      ) {
        // api.dispatch(setActiveIssue(response.data));
      }
    });
  }
);
export const closeIssue = createAsyncThunk(
  'issueRepo/closeIssue',
  async (
    {
      actionTaken,
      explanation,
      issueId,
      issueType
    }: {
      actionTaken: ActionTaken;
      explanation: string;
      issueId: string;
      issueType: IssueType;
    },
    api: GetThunkAPI<AsyncThunkConfig>
  ) => {
    await axios
      .post<UserIssueGroup>(
        endpoints.issues.closeIssue(issueId, issueType, explanation, actionTaken)
      )
      .then((response) => {
        api.dispatch(issueUpdated(response.data));
      });
  }
);

export const sendMessage = createAsyncThunk<
  Message,
  { message: Message; sessionId: string },
  AsyncThunkConfig
>('messagesRepo/sendMessage', async ({ message, sessionId }, api) => {
  const response = await axios.post<Message>(
    endpoints.issues.sendSupportMessage(sessionId),
    message
  );
  api.dispatch(loadedMessages({ sessionId, sorted: [response.data] }));

  const globalState = api.getState() as RootState;
  const issueListState = globalState.messageSessions;
  if (issueListState.activeIssueGroup?.chatSessionId === sessionId) {
    const messagesState = globalState.messagingRepository;
    api.dispatch(setMessages(messagesState.sessionMessages));
  }
  const activeSession = issueListState.activeIssueGroup.chatSession;
  const activeIssue = {
    ...issueListState.activeIssueGroup,
    chatSession: {
      ...activeSession,
      lastMessage: response.data,
      lastMessageDate: response.data.dateReceived
    },
    lastUpdatedAt: response.data.dateReceived
  } as UserIssueGroup;
  api.dispatch(issueUpdated(activeIssue));
  return response.data;
});

export const mergeIssue = createAsyncThunk(
  'issueRepo/mergeIssues',
  async (
    { issueToMerge, masterIssueId }: { issueToMerge: string; masterIssueId: string },
    api: GetThunkAPI<AsyncThunkConfig>
  ) => {
    await axios
      .post<UserIssueGroup>(endpoints.issues.mergeIssue(masterIssueId, issueToMerge))
      .then((response) => {
        api.dispatch(issueUpdated(response.data));
      });
  }
);

export const activeIssueChanged = createAsyncThunk(
  'messagesRepo/activeIssueChanged',
  async (issueGroup: UserIssueGroup, api: GetThunkAPI<AsyncThunkConfig>) => {
    let globalState = api.getState() as RootState;
    let state = globalState.messagingRepository;
    let limit = 100;
    if (state.sessionMessages.has(issueGroup.chatSessionId)) {
      limit = 10;
    }

    api
      .dispatch(getMessages({ limit, sessionId: issueGroup.chatSessionId }))
      .then((messageResult) => {
        const response = messageResult as PayloadAction<{ sessionId: string; sorted: Message[] }>;
        api.dispatch(
          loadedMessages({
            sessionId: response.payload.sessionId,
            sorted: response.payload.sorted
          })
        );
      })
      .then(() => {
        api.dispatch(setActiveIssue(issueGroup));
      })
      .then(() => {
        globalState = api.getState() as RootState;
        state = globalState.messagingRepository;
        api.dispatch(setMessages(state.sessionMessages));
      });
  }
);

export const uploadAttachments = createAsyncThunk(
  'messagesRepo/uploadAttachments',
  async (
    { file, messageId, sessionId }: { file: File; messageId: string; sessionId: string },
    api: GetThunkAPI<AsyncThunkConfig>
  ) => {
    const formData = new FormData();
    formData.append('attachments', file);
    const url = endpoints.issues.uploadAttachments(messageId, sessionId);
    const response = await axios.post<Message>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    api.dispatch(updateMessage({ message: response.data, sessionId: response.data.sessionID }));

    const globalState = api.getState() as RootState;
    const issueListState = globalState.messageSessions;
    if (issueListState.activeIssueGroup?.chatSessionId === response.data.sessionID) {
      const messagesState = globalState.messagingRepository;
      api.dispatch(setMessages(messagesState.sessionMessages));
    }
    const activeSession = issueListState.activeIssueGroup.chatSession;
    const activeIssue = {
      ...issueListState.activeIssueGroup,
      chatSession: {
        ...activeSession,
        lastMessage: response.data,
        lastMessageDate: response.data.dateReceived
      },
      lastUpdatedAt: response.data.dateReceived
    } as UserIssueGroup;
    api.dispatch(issueUpdated(activeIssue));
    return response.data;
  }
);

const messageSessionSlice = createSlice({
  initialState: initialMessagesState,
  name: 'messages',
  reducers: {
    setActiveIssue: (draft, action: PayloadAction<UserIssueGroup>) => {
      draft.activeIssueGroup = action.payload;
    },
    setFilter: (draft, action: PayloadAction<IssueGroupFilter>) => {
      draft.activeFilter = action.payload;
    },
    setIssues: (draft, action: PayloadAction<UserIssueGroup[]>) => {
      draft.issueGroups = action.payload;

      const activeIssue = draft.issueGroups.find(
        (issue) => issue.chatSessionId === draft?.activeIssueGroup?.chatSessionId
      );
      if (activeIssue) {
        draft.activeIssueGroup = activeIssue;
      }
      draft.isFetching = false;
    },
    setMessages: (draft, action: PayloadAction<Map<string, Message[]>>) => {
      if (draft.activeIssueGroup.chatSession != null && action.payload != null) {
        const newList: Message[] = [];

        Array.from(action.payload.values()).forEach((group: Message[]) => {
          newList.push(
            ...group.filter((message) => message.sessionID === draft.activeIssueGroup.chatSessionId)
          );
        });
        draft.messages = newList;
        draft.isFetching = false;
      }
    }
  }
});
export default messageSessionSlice.reducer;
export const { setActiveIssue, setFilter, setIssues, setMessages } = messageSessionSlice.actions;
