import { EventState } from 'src/views/event/model';

export interface EventFilter {
  createdBySponsorId: string;
  state: EventState;
  title: string;
}

export const initialEventFilter: EventFilter = {
  createdBySponsorId: null,
  state: null,
  title: null
};

export interface EventFilterState {
  eventFilter: EventFilter;
}

export const initialEventFilterState: EventFilterState = {
  eventFilter: initialEventFilter
};
