import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  RemoteConfigAssignorFilter,
  initialRemoteConfigAssignorFilter,
  initialRemoteConfigAssignorFilterState
} from './type';

const remoteConfigAssignorFilterSlice = createSlice({
  initialState: initialRemoteConfigAssignorFilterState,
  name: 'remoteConfigAssignorFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.remoteConfigAssignorFilter = initialRemoteConfigAssignorFilter;
    },
    removeFilterItem: (draft, action: PayloadAction<keyof RemoteConfigAssignorFilter>) => {
      const key = action.payload as keyof RemoteConfigAssignorFilter;

      if (key === 'showActive') {
        draft.remoteConfigAssignorFilter.showActive = initialRemoteConfigAssignorFilter.showActive;
      } else if (key === 'showMaster') {
        draft.remoteConfigAssignorFilter.showMaster = initialRemoteConfigAssignorFilter.showMaster;
      } else {
        draft.remoteConfigAssignorFilter[key] = initialRemoteConfigAssignorFilter[key] as any;
      }
    },
    updateFilter: (draft, action: PayloadAction<RemoteConfigAssignorFilter>) => {
      draft.remoteConfigAssignorFilter = {
        ...initialRemoteConfigAssignorFilter,
        ...action.payload
      };
    }
  }
});

export default remoteConfigAssignorFilterSlice.reducer;
export const { clearFilter, removeFilterItem, updateFilter } =
  remoteConfigAssignorFilterSlice.actions;
