import { combineReducers } from '@reduxjs/toolkit';
import clientFilterReducer from './clientFilter/slice';
import eventFilterReducer from './eventFilter/slice';
import invoiceFilterReducer from './invoiceFilter/slice';
import meetingFilterReducer from './meetingFilter/slice';
import messageSessionsReducer from './messaging/messageSessions/slice';
import messagingRepository from './messaging/repository/slice';
import providerCompensationFilterReducer from './providerCompensationFilter/slice';
import providerFilterReducer from './providerFilter/slice';
import reconciliationFilterReducer from './reconciliationFilter/slice';
import remoteConfigAssignorFilterReducer from './remoteConfigAssignorFilter/slice';
import sessionReducer from './session/slice';
import sponsorshipFilterReducer from './sponsorshipFilter/slice';
import userPlanPrototypeReducer from './userPlanPrototypeFilter/slice';

const rootReducer = combineReducers({
  clientFilter: clientFilterReducer,
  eventFilter: eventFilterReducer,
  invoiceFilter: invoiceFilterReducer,
  meetingFilter: meetingFilterReducer,
  messageSessions: messageSessionsReducer,
  messagingRepository,
  providerCompensationFilter: providerCompensationFilterReducer,
  providerFilter: providerFilterReducer,
  reconciliationFilter: reconciliationFilterReducer,
  remoteConfigAssignorFilter: remoteConfigAssignorFilterReducer,
  session: sessionReducer,
  sponsorshipFilter: sponsorshipFilterReducer,
  userPlanPrototypeFilter: userPlanPrototypeReducer
});

export default rootReducer;
